import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Button,
  Card,
  Text,
  Table
} from "tabler-react";
import Plan from '../../interfaces/Plan';
import { formatPrice } from '../../utils/formatUtils';

function PlansList() {

  const history = useHistory();

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    updatePlansList();
  }, []);

  async function updatePlansList() {
    await api.get('plans').then(response => {
      setPlans(response.data);
    });
  }

  function handleEditClick(plan: Plan) {
    history.push(`/plans/form/${plan.id}`);
  }
  
  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Planos</Card.Title>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Nome"},
              { content: "Quantidade de Usuários"},
              {content : "Valor"},
              { content: null }, 
            ]}
            bodyItems={
              plans.map((plan: Plan) => {
                return {
                  key: plan.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {plan.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {plan.max_users}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {formatPrice(plan.price!) }
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(plan)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );

}

export default PlansList;