import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import PlatformCoupon, { PlatformCouponTypeList } from '../../interfaces/PlatformCoupon';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";
import { platform } from 'os';
import Platform from '../../interfaces/Platform';

function PlatformCouponsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: PlatformCoupon = {
    id: '',
    description: '',
    code: '',
    type: 1,
    value: 0,
    platform: {
      id: 0,
      name: '',
      status: 1,
    },
  };

  const [platformCoupon, setPlatformCoupon] = useState<PlatformCoupon>(initialValues);
  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    api.get(`platforms`).then(response => {
      setPlatforms(response.data.platforms);
      setPlatformCoupon({...platformCoupon, platform: response.data.platforms[0]});
    });
    if(!!id) {
      api.get(`platformcoupons/${id}`).then(response => {
        setPlatformCoupon(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPlatformCoupon({...platformCoupon, [name]: value});
  }

  function handlePlatformChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newPlatform = {
      id: parseInt(value)
    } as Platform;
    setPlatformCoupon({...platformCoupon, platform: newPlatform});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    setPlatformCoupon({...platformCoupon, [name]: parseInt(value)});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        let data = {
          description: platformCoupon.description,
          code: platformCoupon.code,
          value: platformCoupon.value,
          type: platformCoupon.type,
          platform_id: platformCoupon.platform.id,
        }

        if(!!platformCoupon.id) {
          api.put(`platformcoupons/${platformCoupon.id}`, data)
          alert('Cupom atualizado!');
        } else {
          api.post('platformcoupons', data)
          alert('Cupom incluído!');
        }

        history.push('/platformcoupons');
      } catch (err) {
        if(!!platformCoupon.id) {
          alert('Erro ao atualizar o cupom!');
        } else {
          alert('Erro ao cadastrar o cupomsd!');
        }
      }
    }, [history, platformCoupon]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Plano"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Descrição">
              <Form.Input
                name="description"
                value={platformCoupon.description || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Código">
              <Form.Input
                name="code"
                value={platformCoupon.code || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Tipo">
              <Form.Select
                value={platformCoupon.type}
                onChange={handleSelectChange}
                name="type"
              >
                { PlatformCouponTypeList.map((type) => (
                  <option
                    key={type.id}
                    value={type.id}
                  >
                    { type.value }
                  </option>
                )) }
              </Form.Select>
            </Form.Group>
            <Form.Group label="Plataforma">
              <Form.Select
                value={platformCoupon.platform.id}
                onChange={handlePlatformChange}
                name="platform"
                disabled={!!id}
              >
                { platforms.map((platform: Platform) => {
                  return (
                    <option
                      key={platform.id}
                      value={platform.id}
                    >
                      { platform.name }
                    </option>
                )}) }
              </Form.Select>
            </Form.Group>
            <Form.Group label="Valor">
              <Form.Input
                name="value"
                value={platformCoupon.value || ""}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default PlatformCouponsForm;