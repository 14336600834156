import Platform from "./Platform";

const PlatformCouponTypeList = [
  {id: 1, value: 'Percentual'},
  {id: 2, value: 'Valor'},
];

const platformCouponTypeDescription = (typeId: number) => {
 return PlatformCouponTypeList.find(type => type.id === typeId)?.value;
}

export { PlatformCouponTypeList, platformCouponTypeDescription };

export default interface PlatformCoupon {
  id: number | "";
  description: string;
  code: string;
  value: number;
  type: number;
  platform: Platform;
}