import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Store, { StoreProgramTypeList, StoreStatusList } from '../../interfaces/Store';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";
import Platform from '../../interfaces/Platform';

function StoresForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Store = {
    id: "",
    name: "",
    cnpj: "",
    email: "",
    platform_options: {},
    status: 1,
    program_type: 1,
    customers: 0,
    cashback_comission: 0,
    comission_percent: 10,
    platform: {
      id: "",
      name: "",
      status: 1
    },
    identifier_magazord: "",
  };

  const [store, setStore] = useState<Store>(initialValues);
  const [platforms, setPlatforms] = useState([]);
  const [password, setPassword] = useState('');

  useEffect(() => {
    api.get(`platforms`).then(response => {
      setPlatforms(response.data.platforms);
      setStore({...store, platform: response.data.platforms[0]});
    });
    if(!!id) {
      api.get(`stores/${id}`).then(response => {
        setStore(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setStore({...store, [name]: value});
  }

  function handleChangePlatformOptions(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const platform_options = {...store.platform_options, [name]: value}
    setStore({...store, platform_options});
  }

  function handleChangePassword(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setPassword(value);
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { name, value } = event.target;
    setStore({...store, [name]: parseInt(value)});
  }

  function handlePlatformChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    const newPlatform = {
      id: parseInt(value)
    } as Platform;
    setStore({...store, platform: newPlatform});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  function getPlatformOptionsFields() {
    switch(store.platform.id){
      case 1:
        return (
          <Fragment>
            <Form.Group label="URL API">
              <Form.Input
                name="platform_url"
                value={(store.platform_options) ? store.platform_options.platform_url : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="Usuário">
              <Form.Input
                name="platform_user"
                value={(store.platform_options) ? store.platform_options.platform_user : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="Senha">
              <Form.Input
                name="platform_password"
                value={(store.platform_options) ? store.platform_options.platform_password : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="ID da Loja">
              <Form.Input
                name="platform_store_id"
                value={(store.platform_options) ? store.platform_options.platform_store_id : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="Identificador Magazord">
              <Form.Input
                name="identifier_magazord"
                value={store.identifier_magazord}
                onChange={handleChange}
              />
            </Form.Group>
          </Fragment>
        )
      case 2:
        return (
          <Fragment>
            <Form.Group label="Código da Loja (Seller ID)">
              <Form.Input
                name="seller_id"
                value={(store.platform_options) ? store.platform_options.seller_id : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="URL API">
              <Form.Input
                name="platform_url"
                value={(store.platform_options) ? store.platform_options.platform_url : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="Code">
              <Form.Input
                name="code"
                value={(store.platform_options) ? store.platform_options.code : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
          </Fragment>
        )
      case 5:
        return (
          <Fragment>
            <Form.Group label="URL API">
              <Form.Input
                name="platform_url"
                value={(store.platform_options) ? store.platform_options.platform_url : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="APP Key">
              <Form.Input
                name="app_key"
                value={(store.platform_options) ? store.platform_options.app_key : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
          </Fragment>
        )
      case 7:
        return (
          <Fragment>
            <Form.Group label="Store ID">
              <Form.Input
                name="store_id"
                value={(store.platform_options) ? store.platform_options.store_id : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="API Key">
              <Form.Input
                name="api_key"
                value={(store.platform_options) ? store.platform_options.api_key : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
          </Fragment>
        )
      case 8:
        return (
          <Fragment>
            <Form.Group label="Usuário">
              <Form.Input
                name="platform_user"
                value={(store.platform_options) ? store.platform_options.platform_user : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
            <Form.Group label="Senha">
              <Form.Input
                name="platform_password"
                value={(store.platform_options) ? store.platform_options.platform_password : ""}
                onChange={handleChangePlatformOptions}
              />
            </Form.Group>
          </Fragment>
        )
      default:
        return (
          <Fragment>

          </Fragment>
        )
    }
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        let data = {
          name: store.name,
          email: store.email,
          status: store.status,
          cnpj: store.cnpj,
          platform_id: store.platform.id,
          platform_options: store.platform_options,
          password: password,
          program_type: store.program_type,
          comission_percent: store.comission_percent,
          identifier_magazord: store.identifier_magazord
        }
        
        if(!!store.id) {
          await api.put(`stores/${store.id}`, data)
          alert('Cliente atualizado!');
        } else {
          await api.post('stores', data)
          alert('Cliente incluído!');
        }

        history.push('/stores');
      } catch (err) {
        if(!!store.id) {
          alert('Erro ao atualizar o cliente!');
        } else {
          alert('Erro ao cadastrar o cliente!');
        }
      }
    }, [history, store, password]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Cliente"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={store.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="CNPJ">
              <Form.Input
                name="cnpj"
                value={store.cnpj || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="E-Mail">
              <Form.Input
                name="email"
                value={store.email || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label={!!id ? "Senha (se não informada, a mesma será mantida)" : "Senha"}>
              <Form.Input
                name="password"
                value={password}
                onChange={handleChangePassword}
              />
            </Form.Group>
            <Form.Group label="Tipo do Programa">
              <Form.Select
                value={store.program_type}
                onChange={handleSelectChange}
                name="program_type"
              >
                { StoreProgramTypeList.map((type) => (
                  <option
                    key={type.id}
                    value={type.id}
                  >
                    { type.value }
                  </option>
                )) }
              </Form.Select>
            </Form.Group>
            {store.program_type === 2 &&
              <Form.Group label="Percentual de Comissão">
                 <Form.Input
                  name="comission_percent"
                  value={store.comission_percent}
                  onChange={handleChange}
                  type="number"
                />
              </Form.Group>
            }
            <Form.Group label="Status">
              <Form.Select
                value={store.status}
                onChange={handleSelectChange}
                name="status"
              >
                { StoreStatusList.map((status) => (
                  <option
                    key={status.id}
                    value={status.id}
                  >
                    { status.value }
                  </option>
                )) }
              </Form.Select>
            </Form.Group>
            <Form.Group label="Plataforma">
              <Form.Select
                value={store.platform.id}
                onChange={handlePlatformChange}
                name="platform"
                disabled={!!id}
              >
                { platforms.map((platform: Platform) => {
                  return (
                    <option
                      key={platform.id}
                      value={platform.id}
                    >
                      { platform.name }
                    </option>
                )}) }
              </Form.Select>
            </Form.Group>
            { getPlatformOptionsFields() }
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default StoresForm;