import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Plan from '../../interfaces/Plan';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";
import { formatPrice } from '../../utils/formatUtils';

function CashbackPlansForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Plan = {
    id: '',
    name: '',
    max_users: 0,
    stripe_id: '',
    price: '',
  };

  const [plan, setPlan] = useState<Plan>(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`cashbackplans/${id}`).then(response => {
        setPlan(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPlan({...plan, [name]: value});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        let data = {
          name: plan.name,
          max_users: plan.max_users
        }

        if(!!plan.id) {
          api.put(`cashbackplans/${plan.id}`, data)
          alert('Plano atualizado!');
        } else {
          api.post('cashbackplans', data)
          alert('Plano incluído!');
        }

        history.push('/cashbackplans');
      } catch (err) {
        if(!!plan.id) {
          alert('Erro ao atualizar o plano!');
        } else {
          alert('Erro ao cadastrar o plano!');
        }
      }
    }, [history, plan]
  );
  
  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Plano"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={plan.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Máximo de Usuários">
              <Form.Input
                name="max_users"
                value={plan.max_users || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Price">
              <Form.Input
                name="stripe_id"
                value={formatPrice(plan.price!) || ""}
                disabled={true}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default CashbackPlansForm;