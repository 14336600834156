import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import User from '../../interfaces/User';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function UsersForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: User = {
    id: "",
    name: "",
    username: "",
    master: false,
  };

  const [user, setUser] = useState<User>(initialValues);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if(!!id) {
      api.get(`users/${id}`).then(response => {
        setUser(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setUser({...user, [name]: value});
  }

  function handleChangePassword(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setPassword(value);
  }

  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, checked } = event.target;
    setUser({...user, [name]: checked});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();
        const data = {
          "name": user.name,
          "username": user.username,
          "password": password,
          "master": user.master,
        }

        if(!!user.id) {
          await api.put(`users/${user.id}`, data)
          alert('Usuário atualizado!');
        } else {
          await api.post('users', data)
          alert('Usuário incluído!');
        }

        history.push('/users');
      } catch (err) {
        if(!!user.id) {
          alert('Erro ao atualizar o usuário!');
        } else {
          alert('Erro ao cadastrar o usuário!');
        }
      }
    }, [history, user, password]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Usuário"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={user.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Login">
              <Form.Input
                name="username"
                value={user.username || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Senha" hidden={!!id}>
              <Form.Input
                name="password"
                type="password"
                value={password}
                onChange={handleChangePassword}
              />
            </Form.Group>
            <Form.Group label="Master">
              <Form.Switch
                name="master"
                onChange={handleCheckboxChange}
                checked={!!user.master ? true : false}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default UsersForm;