const PlatformStatusList = [
  {id: 1, value: 'Em Desenvolvimento'},
  {id: 2, value: 'Em Produção'},
];

const platformStatusDescription = (statusId: number) => {
 return PlatformStatusList.find(status => status.id === statusId)?.value;
}

export { PlatformStatusList, platformStatusDescription };

export default interface Platform {
  id: number | "";
  name: string;
  status: number;
}