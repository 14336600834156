import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Button,
  Card,
  Text,
  Table
} from "tabler-react";

import CashbackPlan from '../../interfaces/CashbackPlan';
import { formatPrice } from '../../utils/formatUtils';

function CashbackPlansList() {

  const history = useHistory();

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    updatePlansList();
  }, []);

  async function updatePlansList() {
    await api.get('cashbackplans').then(response => {
      setPlans(response.data);
    });
  }

  function handleEditClick(cashbackPlan: CashbackPlan) {
    history.push(`/cashbackplans/form/${cashbackPlan.id}`);
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Planos de Cashback</Card.Title>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Nome"},
              { content: "Quantidade de Usuários"},
              { content: "Valor"},
              { content: null },
            ]}
            bodyItems={
              plans.map((cashbackPlan: CashbackPlan) => {
                return {
                  key: cashbackPlan.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {cashbackPlan.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {cashbackPlan.max_users}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {formatPrice(cashbackPlan.price)}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(cashbackPlan)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
      </Page.Content>
    </SiteWrapper>
  );

}

export default CashbackPlansList;