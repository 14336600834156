import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Platform, { PlatformStatusList } from '../../interfaces/Platform';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function PlatformsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Platform = {
    id: "",
    name: "",
    status: 1,
  };

  const [platform, setPlatform] = useState<Platform>(initialValues);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if(!!id) {
      api.get(`platforms/${id}`).then(response => {
        setPlatform(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setPlatform({...platform, [name]: value});
  }

  function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;
    setPlatform({...platform, status: parseInt(value)});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        let data = {
          name: platform.name,
          status: platform.status
        }

        if(!!platform.id) {
          api.put(`platforms/${platform.id}`, data)
          alert('Plataforma atualizada!');
        } else {
          api.post('platforms', data)
          alert('Plataforma incluída!');
        }

        history.push('/platforms');
      } catch (err) {
        if(!!platform.id) {
          alert('Erro ao atualizar a plataforma!');
        } else {
          alert('Erro ao cadastrar a plataforma!');
        }
      }
    }, [history, platform, password]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Plataforma"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Nome">
              <Form.Input
                name="name"
                value={platform.name || ""}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Status">
              <Form.Select
                value={platform.status}
                onChange={handleSelectChange}
                name="type"
              >
                { PlatformStatusList.map((status) => (
                  <option
                    key={status.id}
                    value={status.id}
                  >
                    { status.value }
                  </option>
                )) }
              </Form.Select>
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default PlatformsForm;