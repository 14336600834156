import React, { ChangeEvent, useState } from "react";
import { Formik } from "formik";
import { Button, Form, StandaloneFormPage, LoginPage as TablerLoginPage } from "tabler-react";
import { useHistory } from 'react-router-dom';

import { useAuth } from "../hooks/AuthContext";

import logo from "../assets/img/logo.svg";

import './login.css'

type Props = {};

function Login(props: Props) {
  const history = useHistory();

  const { signIn, user} = useAuth();

  const [emailMagazord, setEmailMagazord] = useState<string>()
  const [passwordMagazord, setPasswordMagazord] = useState<string>()
  const [code, setCode]= useState<string>()
  const [twoFactor , settwoFactor] = useState(false);

  async function handleLogin (email?: string, password?: string) {
    try { 
      await signIn({
        username: emailMagazord ?? email,
        password: password? password : passwordMagazord!,
        secret: code ?? "1",
      });

      if (!!email && !!password){
        setEmailMagazord(email)
        setPasswordMagazord(password)
      }
      settwoFactor(true); 
    } catch (err) {
      alert("Login ou senha incorretos");
    } 
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validate={values => {
        // same as above, but feel free to move this into a class method now.
        let errors: any = {};
        if (!values.email) {
          errors.email = "Obrigatório";
        } else if (!values.password) {
          errors.password = "Obrigatório";
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        await handleLogin(values.email, values.password);
        if(!!user) {history.push('dashboard')};
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <>
        {twoFactor && !user?
          <StandaloneFormPage
          imageURL={logo}
          children={
            <Form 
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin(); 
            }}
            className="form-name"
            >
            <Form.Group 
            label="Autenticação" 
            className="form-reset"
            >
            <Form.Input
              placeholder="Código 2FA"
              name="password"
              onChange={(event: ChangeEvent<HTMLInputElement>) => setCode(event.target.value)}
            />
              </Form.Group>
            <Button
              size="sm"
              href="#"
              RootComponent="a"
              color="primary"  
              onClick={handleLogin}
              className="button-store"
              disabled={!code}
              >
              Login
            </Button>
          </Form> 
          }/>
          : 
          <TablerLoginPage
            onSubmit={handleSubmit}
            onChange={handleChange}
            onBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
            logoUrl={logo}
            strings={{
              title:"Login",
              buttonText:"Login",
              emailLabel:"Usuário",
              emailPlaceholder:"Usuário",
              passwordLabel:"Senha",
              passwordPlaceholder:"Senha",
            }}
          />
        }
      </>
      )}
    />
  );
}

export default Login;