import React, { useState, useEffect, ChangeEvent, useCallback, FormEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import Billing from '../../interfaces/Billing';

import {
  Page,
  Button,
  Form,
  Card
} from "tabler-react";

function BillingsForm() {

  const history = useHistory();

  const { id } = useParams();

  const initialValues: Billing = {
    id: ''
  };

  const [billing, setBilling] = useState<Billing>(initialValues);

  useEffect(() => {
    if(!!id) {
      api.get(`billings/${id}`).then(response => {
        setBilling(response.data);
      });
    }
  }, [id]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setBilling({...billing, [name]: value});
  }

  function handleClickCancel(event: FormEvent) {
    event.preventDefault();
    history.goBack();
  }

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      try {
        event.preventDefault();

        let data = {
          month: billing.month,
          year: billing.year,
          value: billing.value,
          store_id: billing.store?.id,
        }

        if(!!billing.id) {
          api.put(`billings/${billing.id}`, data)
          alert('Fatura atualizada!');
        } else {
          api.post('billings', data)
          alert('Fatura incluída!');
        }

        history.push('/billings');
      } catch (err) {
        if(!!billing.id) {
          alert('Erro ao atualizar a fatura!');
        } else {
          alert('Erro ao cadastrar a fatura!');
        }
      }
    }, [history, billing]
  );

  return (
    <SiteWrapper>
      <Form onSubmit={handleSubmit}>
        <Page.Card
          title="Fatura"
          footer={
            <Card.Footer>
              <div className="d-flex">
                <Button link onClick={handleClickCancel} >Cancelar</Button>
                <Button type="submit" color="primary" className="ml-auto">
                  Salvar
                </Button>
              </div>
            </Card.Footer>
          }
        >
          <Form.FieldSet>
            <Form.Group label="Ano">
              <Form.Input
                name="year"
                value={billing.year}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Mês">
              <Form.Input
                name="month"
                value={billing.month}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group label="Valor">
              <Form.Input
                name="value"
                value={billing.value}
                onChange={handleChange}
              />
            </Form.Group>
          </Form.FieldSet>
        </Page.Card>
      </Form>
    </SiteWrapper>
  );
}

export default BillingsForm;