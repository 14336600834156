import Platform from "./Platform";

const StoreStatusList = [
  {id: 1, value: 'Em Homologação'},
  {id: 2, value: 'Em Produção'},
  {id: 3, value: 'Cancelado'},
];

const StoreProgramTypeList = [
  {id: 1, value: 'Fidelidade'},
  {id: 2, value: 'Cashback'},
];

const storeStatusDescription = (statusId: number) => {
 return StoreStatusList.find(status => status.id === statusId)?.value;
}

const storeProgramTypeDescription = (typeId: number) => {
 return StoreProgramTypeList.find(type => type.id === typeId)?.value;
}

export { StoreStatusList, storeStatusDescription, StoreProgramTypeList, storeProgramTypeDescription };

interface PlatformOptions {
  [key: string]: any;
}

export default interface Store {
  id: number | "";
  name: string;
  cnpj: string;
  email: string;
  status: number;
  platform: Platform
  platform_options: PlatformOptions;
  password?: string;
  customers: number;
  program_type: number;
  cashback_comission?: number;
  comission_percent: number;
  identifier_magazord?: string;
}