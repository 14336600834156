import React from "react";
import { NavLink as ReactRouterNavLink } from "react-router-dom";

import { Site } from "tabler-react";

const navBarItems = [
  {
    value: "Dashboard",
    to: "/",
    icon: "home",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Clientes",
    to: "/stores",
    icon: "briefcase",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Cobranças",
    to: "/billings",
    icon: "trending-up",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Usuários",
    to: "/users",
    icon: "users",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Plataformas",
    to: "/platforms",
    icon: "monitor",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Planos",
    to: "/plans",
    icon: "dollar-sign",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Planos Cashback",
    to: "/cashbackplans",
    icon: "dollar-sign",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Cupons de Desconto",
    to: "/platformcoupons",
    icon: "shopping-bag",
    linkAs: ReactRouterNavLink,
    linkProps: { exact: true },
  },
  {
    value: "Últimas Recuperações",
    icon: "compass",
    subItemsObjects: [
      {
        value: 'Fidelidade',
        to: "/recoveries",
        icon: "lock",
        linkAs: ReactRouterNavLink,
        linkProps: { exact: true },
      },
      {
        value: 'Cashback',
        to: "/cashbackrecoveries",
        icon: "dollar-sign",
        linkAs: ReactRouterNavLink,
        linkProps: { exact: true },
      },
    ]
  },
];

const SiteNav = () => {
  return (
    <Site.Nav
      logoURL="/img/logo.svg"
      isSide={true}
      side="left"
      itemsObjects={navBarItems}
    />
  );
};

export default SiteNav;