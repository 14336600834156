import React, { useState, useEffect, ChangeEvent, FormEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';

import {
  Page,
  Button,
  Card,
  Text,
  Table,
  Form
} from "tabler-react";

import Billing from '../../interfaces/Billing';
import ReactPaginate from 'react-paginate';

function BillingsList() {

  const history = useHistory();

  const [billings, setBillings] = useState([]);
  const [storeNameSearch, setStoreNameSearch] = useState('');
  const [yearSearch, setYearSearch] = useState('');
  const [monthSearch, setMonthSearch] = useState('');
  const [page, setPage]= useState(1);
  const [pageCount, setPageCount]= useState(0);

  useEffect(() => {
    updatePlansList();
  }, [page]);

  async function updatePlansList() {
    const params = {
      page,
      store: storeNameSearch,
      month: monthSearch,
      year: yearSearch
    };
    await api.get('billings', { params }).then(response => {
      setBillings(response.data.billings);
      setPageCount(response.data.pages);
    });
  }

  function handleEditClick(billing: Billing) {
    history.push(`/billings/form/${billing.id}`);
  }

  async function handleSubmitSearch(event: FormEvent) {
    event.preventDefault();
    await updatePlansList();
  }

  function handleClickAdd() {
    history.push('/billings/form');
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setStoreNameSearch(event.target.value);
  }

  function handleYearChange(event: ChangeEvent<HTMLInputElement>) {
    setYearSearch(event.target.value);
  }

  function handleMonthSearch(event: ChangeEvent<HTMLInputElement>) {
    setMonthSearch(event.target.value);
  }

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Cobranças</Card.Title>
            <Card.Options>
              <Form onSubmit={handleSubmitSearch}>
                <Form.InputGroup>
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Loja"
                    name="name"
                    onChange={handleNameChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Ano"
                    name="name"
                    onChange={handleYearChange}
                  />
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Mês"
                    name="name"
                    onChange={handleMonthSearch}
                  />
                  <Button
                    RootComponent="a"
                    color="secondary"
                    size="sm"
                    icon="plus"
                    onClick={handleClickAdd}
                  >
                    Incluir
                  </Button>
                </Form.InputGroup>
              </Form>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Ano"},
              { content: "Mês"},
              { content: "Loja"},
              { content: "Valor"},
              { content: null },
            ]}
            bodyItems={
              billings.map((billing: Billing) => {
                return {
                  key: billing.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {billing.year}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {billing.month}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {billing?.store?.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          {billing.value}
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(billing)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
        <Card.Footer>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </Card.Footer>
      </Page.Content>
    </SiteWrapper>
  );

}

export default BillingsList;