import React, { useState, useEffect, useCallback, FormEvent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import SiteWrapper from "../../base/SiteWrapper";

import api from '../../services/api';
import Platform, { platformStatusDescription } from '../../interfaces/Platform';

import {
  Page,
  Button,
  Card,
  Text,
  Table,
  Form
} from "tabler-react";
import ReactPaginate from 'react-paginate';

function PlatformsList() {

  const history = useHistory();

  const [platforms, setPlatforms] = useState([]);
  const [nameSearch, setNameSearch] = useState('');
  const [page, setPage]= useState(1);
  const [pageCount, setPageCount]= useState(0);

  useEffect(() => {
    updatePlatformsList();
  }, [page]);

  async function updatePlatformsList() {
    const params = {
      page,
      name: nameSearch
    };
    await api.get('platforms', { params }).then(response => {
      setPlatforms(response.data.platforms);
      setPageCount(response.data.pages);
    });
  }

  async function handleSubmitSearch(event: FormEvent) {
    event.preventDefault();

    await updatePlatformsList();
  }

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setNameSearch(event.target.value);
  }

  function handleEditClick(platform: Platform) {
    history.push(`/platforms/form/${platform.id}`);
  }

  function handleClickAdd() {
    history.push('/platforms/form');
  }

  const handlePaginate = useCallback((selectedItem: { selected: number }) => {
    setPage(selectedItem.selected + 1);
  }, []);

  return (
    <SiteWrapper>
      <Page.Content>
        <Card>
          <Card.Header>
            <Card.Title>Plataformas</Card.Title>
            <Card.Options>
              <Form onSubmit={handleSubmitSearch}>
                <Form.InputGroup>
                  <Form.Input
                    className="form-control-sm"
                    placeholder="Nome"
                    name="name"
                    onChange={handleNameChange}
                  />
                  <Button
                    RootComponent="a"
                    color="secondary"
                    size="sm"
                    icon="plus"
                    onClick={handleClickAdd}
                  >
                    Incluir
                  </Button>
                </Form.InputGroup>
              </Form>
            </Card.Options>
          </Card.Header>
          <Table
            responsive
            className="card-table table-vcenter text-nowrap"
            headerItems={[
              { content: "Nome"},
              { content: "Status"},
              { content: null },
            ]}
            bodyItems={
              platforms.map((platform: Platform) => {
                return {
                  key: platform.id,
                  item: [
                    {
                      content: (
                        <Text>
                          {platform.name}
                        </Text>
                      ),
                    },
                    {
                      content: (
                        <Text>
                          { platformStatusDescription(platform.status) }
                        </Text>
                      ),
                    },
                    {
                      alignContent: "right",
                      content: (
                        <>
                          <Button
                            RootComponent="a"
                            color="secondary"
                            size="sm"
                            className="m-1"
                            icon="edit"
                            onClick={() => handleEditClick(platform)}
                          />
                        </>
                      ),
                    },
                  ]
                }
              })
            }
          />
        </Card>
        <Card.Footer>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePaginate}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </Card.Footer>
      </Page.Content>
    </SiteWrapper>
  );
}

export default PlatformsList;