import React from 'react';
import Route from './Route';
import { Switch } from 'react-router-dom';

import HomePage from "../pages/HomePage";

import UsersList from "../pages/users/UsersList";
import UsersForm from "../pages/users/UsersForm";

import StoresList from "../pages/stores/StoresList";
import StoresForm from "../pages/stores/StoresForm";

import PlatformList from "../pages/platforms/PlatformsList";
import PlatformForm from "../pages/platforms/PlatformsForm";

import PlatformCouponsList from "../pages/platformCoupons/PlatformCouponsList";
import PlatformCouponsForm from "../pages/platformCoupons/PlatformCouponsForm";

import PlansList from "../pages/plans/PlansList";
import PlansForm from "../pages/plans/PlansForm";

import CashbackPlansList from "../pages/cashbackPlans/CashbackPlansList";
import CashbackPlansForm from "../pages/cashbackPlans/CashbackPlansForm";

import BillingsList from "../pages/billings/BillingsList";
import BillingsForm from "../pages/billings/BillingsForm";

import RecoveriesList from "../pages/recoveries/RecoveriesList";
import CashbackRecoveriesList from "../pages/cashbackRecoveries/CashbackRecoveriesList";

import Login from "../pages/Login";
import Error404 from "../pages/exceptions/404";

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/dashboard" component={HomePage} isPrivate />

	  <Route exact path="/stores" component={StoresList} isPrivate />
	  <Route exact path="/stores/form" component={StoresForm} isPrivate />
	  <Route exact path="/stores/form/:id" component={StoresForm} isPrivate />

	  <Route exact path="/users" component={UsersList} isPrivate />
	  <Route exact path="/users/form" component={UsersForm} isPrivate />
	  <Route exact path="/users/form/:id" component={UsersForm} isPrivate />

	  <Route exact path="/platforms" component={PlatformList} isPrivate />
	  <Route exact path="/platforms/form" component={PlatformForm} isPrivate />
	  <Route exact path="/platforms/form/:id" component={PlatformForm} isPrivate />

	  <Route exact path="/platformcoupons" component={PlatformCouponsList} isPrivate />
	  <Route exact path="/platformcoupons/form" component={PlatformCouponsForm} isPrivate />
	  <Route exact path="/platformcoupons/form/:id" component={PlatformCouponsForm} isPrivate />

	  <Route exact path="/plans" component={PlansList} isPrivate />
	  <Route exact path="/plans/form" component={PlansForm} isPrivate />
	  <Route exact path="/plans/form/:id" component={PlansForm} isPrivate />

	  <Route exact path="/cashbackplans" component={CashbackPlansList} isPrivate />
	  <Route exact path="/cashbackplans/form" component={CashbackPlansForm} isPrivate />
	  <Route exact path="/cashbackplans/form/:id" component={CashbackPlansForm} isPrivate />

	  <Route exact path="/billings" component={BillingsList} isPrivate />
	  <Route exact path="/billings/form" component={BillingsForm} isPrivate />
	  <Route exact path="/billings/form/:id" component={BillingsForm} isPrivate />

    <Route exact path="/recoveries" component={RecoveriesList} isPrivate />
    <Route exact path="/cashbackrecoveries" component={CashbackRecoveriesList} isPrivate />
    <Route component={Error404} />
  </Switch>
);

export default Routes;