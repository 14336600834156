import React, { useEffect, useState } from "react";

import SiteWrapper from "../base/SiteWrapper";

import C3Chart from "../C3Chart";

import api from '../services/api';

import {
  Page,
  Card,
  Grid,
  colors
} from "tabler-react";

import { ChartConfiguration } from "c3";

type cardsConfig = ChartConfiguration;

interface Kpis {
  production: number;
  staging: number;
}

interface CustomerKpis {
  total: number;
  active: number;
}

interface TopStores {
  customers: number[]
  names: string[]
}

function Home() {
  const [topStores, setTopStores] = useState<TopStores>({customers: [], names: []} as TopStores);
  const [kpis, setKpis] = useState<Kpis>({ production: 0, staging: 0, revenue: 0 } as Kpis);
  const [customerKpis, setCustomerKpis] = useState<CustomerKpis>({ total: 0, active: 0 } as CustomerKpis);
  const [revenue, setRevenue] = useState(0);
  const [revenuePreview, setRevenuePreview] = useState(0);

  useEffect(() => {
    api.get('topStores').then(response => {
      setTopStores(response.data);
    });
    api.get('dashboardKpis').then(response => {
      setKpis(response.data);
    });
    api.get('dashboardCustomerKpis').then(response => {
      setCustomerKpis(response.data);
    });
    api.get('dashboardRevenue').then(response => {
      setRevenue(response.data);
    });
    api.get('dashboardRevenue?preview=1').then(response => {
      setRevenuePreview(response.data);
    });
  }, [])

  const cards: cardsConfig[] = [
    {
      title: {
        text: "Maiores Clientes"
      },
      data: {
        columns: [
          // each columns data
          ["Clientes", ...topStores.customers]
        ],
        type: "bar", // default type of chart
        colors: {
          tax: colors.blue
        },
        names: {
          // name of each serie
          tax: "Clientes"
        },
      },
      axis: {
        x: {
          type: "category",
          // name of each category
          categories: topStores.names,
        },
      },
    }
  ];

  return (
    <SiteWrapper>
      <Page.Content title="Dashboard">
        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>Lojas</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Em Produção"
                      body={kpis.production}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Em Homologação"
                      body={kpis.staging}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Receita Atual"
                      body={"R$ " + revenue.toFixed(2)}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={4}>
                    <Card
                      title="Previsão Próximo Mês"
                      body={"R$ " + revenuePreview.toFixed(2)}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col sm={12} lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>Clientes</Card.Title>
              </Card.Header>
              <Card.Body>
                <Grid.Row>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Total"
                      body={customerKpis.total}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12} lg={6}>
                    <Card
                      title="Ativos"
                      body={customerKpis.active}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {cards.map((chart, i) => (
            <Grid.Col key={i} md={6} xl={12}>
              <Card>
                <Card.Header>
                  <Card.Title>{chart.title?.text}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <C3Chart
                    data={chart.data}
                    axis={chart.axis}
                    legend={{
                      show: false, //hide legend
                    }}
                    padding={{
                      bottom: 0,
                      top: 0,
                    }}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default Home;